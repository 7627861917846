var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex_column stake"},[_c('div',{staticClass:"staker"},[_c('Chooser',{attrs:{"routes":[
        { text: 'Stake', cb: _vm.chooseCb },
        { text: 'Unstake', cb: _vm.chooseCb } ],"currentActive":0}}),_c('div',{staticClass:"stakePage"},[_c('div',{staticClass:"sPElement input"},[_c('div',{staticClass:"inputBody"},[_c('div',{staticClass:"flex_row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Damount),expression:"Damount"}],staticClass:"token-amount-input",attrs:{"inputmode":"decimal","title":"Token Amount","autocomplete":"off","autocorrect":"off","type":"text","pattern":"^[0-9]*[.,]?[0-9]*$","placeholder":"0.0","minlength":"1","maxlength":"39","spellcheck":"false","value":""},domProps:{"value":(_vm.Damount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.Damount=$event.target.value}}}),_c('div',{staticClass:"ant-col"},[_vm._v(" "+_vm._s(_vm.isDeposit ? " ETH" : _vm.get_wsgETH ? "wsgETH" : "sgETH")+" ")])]),_c('div',{staticClass:"balance",attrs:{"id":"balance"},on:{"click":_vm.onMAX}},[_vm._v(" wallet: "+_vm._s(_vm.balance)+" ")]),_c('div',{class:_vm.isDeposit ? 'background2' : 'background3'})])]),_c('ImageVue',{staticClass:"sPElement",attrs:{"src":'down.png',"size":'30px'}}),_c('div',{staticClass:"sPElement input"},[_c('div',{staticClass:"inputBody"},[_c('div',{staticClass:"flex_row"},[_c('input',{staticClass:"token-amount-input",attrs:{"inputmode":"decimal","title":"Token Amount","autocomplete":"off","autocorrect":"off","type":"text","pattern":"^[0-9]*[.,]?[0-9]*$","placeholder":"0.0","minlength":"1","maxlength":"39","spellcheck":"false","readonly":""},domProps:{"value":_vm.isDeposit
                  ? _vm.get_wsgETH
                    ? _vm.ethTowsgETH
                    : (_vm.Damount / 32) * 32
                  : _vm.get_wsgETH
                  ? _vm.willGet
                  : (_vm.Damount / 32) * (32 + _vm.adminFee)}}),_c('div',{staticClass:"ant-col"},[_vm._v(" "+_vm._s(_vm.isDeposit ? (_vm.get_wsgETH ? "wsgETH" : "sgETH") : "ETH")+" ")])]),_c('div',{staticClass:"balance",attrs:{"id":"balance"},on:{"click":_vm.onMAX}},[_vm._v(" wallet: "+_vm._s(_vm.otherBalance)+" ")]),_c('div',{class:_vm.isDeposit ? 'background3' : 'background2'})])]),_c('div',{attrs:{"id":"gas"}},[_c('span',{attrs:{"id":"gas"}},[_vm._v("Gas")]),_c('Chooser',{attrs:{"routes":[
            {
              text: (
                _vm.gas.low.maxFeePerGas + _vm.gas.low.maxPriorityFeePerGas
              ).toFixed(1),
              cb: _vm.updateGasCb,
            },
            {
              text: (
                _vm.gas.medium.maxFeePerGas + _vm.gas.medium.maxPriorityFeePerGas
              ).toFixed(1),
              cb: _vm.updateGasCb,
            },
            {
              text: (
                _vm.gas.high.maxFeePerGas + _vm.gas.high.maxPriorityFeePerGas
              ).toFixed(1),
              cb: _vm.updateGasCb,
            } ],"currentActive":1}})],1),_c('div',{staticClass:"navbar s-toggle"},[_c('span',{attrs:{"id":"gas"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.get_wsgETH),expression:"get_wsgETH"}],attrs:{"id":"get-wsgETH","type":"checkbox","name":"get-wsgETH"},domProps:{"checked":Array.isArray(_vm.get_wsgETH)?_vm._i(_vm.get_wsgETH,null)>-1:(_vm.get_wsgETH)},on:{"change":function($event){var $$a=_vm.get_wsgETH,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.get_wsgETH=$$a.concat([$$v]))}else{$$i>-1&&(_vm.get_wsgETH=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.get_wsgETH=$$c}}}}),_c('label',{attrs:{"for":"get-wsgETH"}},[_vm._v(_vm._s(_vm.isDeposit ? "Get" : "Use")+" Wrapped SgETH (interest bearing)")])])]),(
          !_vm.isDeposit &&
            _vm.get_wsgETH &&
            this.enoughFundsInExitPool &&
            !this.enoughApproved
        )?_c('ApprovalButton',{staticClass:"StakeButton",attrs:{"ABI_token":_vm.wsgETH,"ABI_spender":_vm.validator,"amount":this.Damount,"cb":this.getUserApprovedwsgEth}}):_c('dapp-tx-btn',{staticClass:"StakeButton",class:{
          switch_active: _vm.buttonText == 'Unstake',
        },attrs:{"click":_vm.genSubmit}},[_c('span',[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])],1)],1),_c('StakeGauge',{staticClass:"gauge"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }